$antd-table-head-bg-color: rgba(0, 202, 149, 0.5);
$antd-table-data-bg-color: rgba(0, 202, 149, 0.3);
$antd-collapse-text-color: #cdddf7;

$title-text-color: #bbf5fd;
$home-userinfo-text-color: #b2cfee;

$icons-color: #89e5ff;

$module-text-color: #bcdcff;

$data-text-color: #20dbfd;
$data-text-shadow-color: #00d8ff;

$login-border-color: #2261ae;
.container {
  overflow: scroll;
  position: relative;
  width: 100%;
  height: 100%;
  // padding-top: 10px;
  padding-top: 0.7%;
  // overflow: hidden;
  background-size: cover;
  background-image: url('../../assets/images/pageBg.png');
}
.wrap {
  width: 100%;
  // height: 30rem;
  height:100%;
}

.text {
  font-size: large;
  color:$icons-color;
}


.content_wrap {
  display: flex;
  width: 100%;
  height: 96%;
  flex-wrap: nowrap;
  .lead_content {
    width: 15%;
    height: 100%;
    padding: 0 0.6rem 0.6rem;
  }
  .monitor_comtent{
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 0.6rem 0.6rem;
  }
  .left1_content {
    width: 50%;
    // height: auto;
    height: 100%;
    padding: 0.45rem 0.6rem 0.6rem;
  }
  .left2_content {
    width: 50%;
    // height: auto;
    height: 100%;
    padding: 0 0.6rem 0.6rem;
  }
  .left2_1{
    width: 100%;
    height: 60%;
  }
  .left2_2{
    width: 100%;
    height: 40%;
  }
}
  // .center-page {
  //   flex: 1;
  // }



$antd-table-head-bg-color: rgba(0, 202, 149, 0.5);
$antd-table-data-bg-color: rgba(0, 202, 149, 0.3);
$antd-collapse-text-color: #cdddf7;

$title-text-color: #bbf5fd;
$home-userinfo-text-color: #b2cfee;

$icons-color: #89e5ff;

$module-text-color: #bcdcff;

$data-text-color: #20dbfd;
$data-text-shadow-color: #00d8ff;

$login-border-color: #2261ae;
.wrap {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  background-image: url('../../assets/images/loginBg.jpg');
}

.login {
  display: flex;
  width: 600px;
  margin: auto;
  flex-direction: column;
}

.title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: $title-text-color;
}

.input {
  height: 40px;
  border: none;
  border-radius: 25px;
  -webkit-tap-highlight-color: transparent;
  background: hsla(0, 0%, 100%, .1) !important; // 不加important错误时又变白色
}

#logup_username {
  margin-left: 5px;
  color: #FFFFFF;
  background-color: transparent;
}

#logup_password {
  @extend #logup_username;
}

#logup_phone {
  @extend #logup_username;
}

#login_password {
  @extend #logup_username;
}

#login_phone {
  @extend #logup_username;
}

.icon {
  font-size: 18px;
  color: #FFFFFF;
}

.button {
  height: 32px;
  border-radius: 16px;
  border: 1px solid #01d1f2;
  background: hsla(0, 0%, 100%, .0);
}

.particles {
  position: absolute;
  width: 100%;
  height: 100%;
}

.antd_form {
  margin: auto;
}

.container {
	border-radius:0.7rem;
	box-shadow: 0 0.9rem 1.7rem rgba(0, 0, 0, 0.25),
		0 0.7rem 0.7rem rgba(0, 0, 0, 0.22);
	height: 420px;
	max-width: 758px;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.container_form {
	height: 100%;
	position: absolute;
  display: flex;
	top: 0;
	transition: all 0.6s ease-in-out;
}

.signin_opacity {
  opacity: 0;
}

.container_signin {
	left: 0;
	width: 50%;
	z-index: 2;
}

.container.right_panel_active .container_signin {
	transform: translateX(100%);
}

.container_signup {
	left: 0;
	opacity: 0;
	width: 50%;
	z-index: 1;
}

.container.right_panel_active .container_signup {
	animation: show 0.6s;
	opacity: 1;
	transform: translateX(100%);
	z-index: 5;
}

.container_overlay {
	height: 100%;
	left: 50%;
	overflow: hidden;
	position: absolute;
	top: 0;
	transition: transform 0.6s ease-in-out;
	width: 50%;
	z-index: 100;
}

.container.right_panel_active .container_overlay {
	transform: translateX(-100%);
}

.overlay {
  background-image: url('../../assets/images/loginBg.jpg');
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%;
	left: -100%;
	position: relative;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
	width: 200%;
}

.container.right_panel_active .overlay {
	transform: translateX(50%);
}

.overlay_panel {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	position: absolute;
	text-align: center;
	top: 0;
	transform: translateX(0);
	transition: transform 0.6s ease-in-out;
	width: 50%;
}

.overlay_left {
	transform: translateX(-20%);
}

.container.right_panel_active .overlay_left {
	transform: translateX(0);
}

.overlay_right {
	right: 0;
	transform: translateX(0);
}

.container.right_panel_active .overlay_right {
	transform: translateX(20%);
}

.btn {
  background-color: transparent;
	border: 1px solid $login-border-color;
	color: white;
  border-radius: 5px;
	cursor: pointer;
	font-weight: bold;
	letter-spacing: 0.1rem;
	padding: 0.9rem 4rem;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
  &:active {
    transform: scale(0.95);
  }
  &:focus {
    outline: none;
  }
  &::after {
    content: "→";
    font-family: "Maax-Bold", sans-serif;
    margin-left: 3px;
    left: 0px;
  }
  &:hover {
    &::after {
      left: 3px;
      transition: left 0.1s ease-in-out 0s, right 0.1s ease-in-out 0s;
      display: inline-block;
      position: relative;
    }
  }
}

@keyframes show {
	0%,
	49.99% {
		opacity: 0;
		z-index: 1;
	}

	50%,
	100% {
		opacity: 1;
		z-index: 5;
	}
}


$antd-table-head-bg-color: rgba(0, 202, 149, 0.5);
$antd-table-data-bg-color: rgba(0, 202, 149, 0.3);
$antd-collapse-text-color: #cdddf7;

$title-text-color: #bbf5fd;
$home-userinfo-text-color: #b2cfee;

$icons-color: #89e5ff;

$module-text-color: #bcdcff;

$data-text-color: #20dbfd;
$data-text-shadow-color: #00d8ff;

$login-border-color: #2261ae;
.air_linechart {
  width: 60%;
  height: 100%;
}

.air_piechart {
  width: 40%;
  height: 100%;
}

.pm_chart {
  width: 100%;
  // height: 8rem;
  height: 20%;
  // margin-top: 10px;
}
$antd-table-head-bg-color: rgba(0, 202, 149, 0.5);
$antd-table-data-bg-color: rgba(0, 202, 149, 0.3);
$antd-collapse-text-color: #cdddf7;

$title-text-color: #bbf5fd;
$home-userinfo-text-color: #b2cfee;

$icons-color: #89e5ff;

$module-text-color: #bcdcff;

$data-text-color: #20dbfd;
$data-text-shadow-color: #00d8ff;

$login-border-color: #2261ae;
@import '~antd/dist/antd.css';

html, body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

#root {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1, h2, p {
  margin: 0;
  font-weight: unset;
}

// 修改输入框自动填充样式
input:-internal-autofill-previewed,
input:-internal-autofill-selected {
  -webkit-text-fill-color: #FFFFFF !important;
  transition: background-color 5000s ease-in-out 0s !important;
}


$antd-table-head-bg-color: rgba(0, 202, 149, 0.5);
$antd-table-data-bg-color: rgba(0, 202, 149, 0.3);
$antd-collapse-text-color: #cdddf7;

$title-text-color: #bbf5fd;
$home-userinfo-text-color: #b2cfee;

$icons-color: #89e5ff;

$module-text-color: #bcdcff;

$data-text-color: #20dbfd;
$data-text-shadow-color: #00d8ff;

$login-border-color: #2261ae;
@import '../../../assets/styles/common.scss';

// .soil_wrap {
//   width: 100%;
//   height: 35%;
//   .borderbox13 {
//     padding: 0.5rem 0.7rem 0 0.7rem;
//   }
// }

.scoll_wrap {
  width: 100%;
  // height: 10rem;
  height: 45%;
  margin-top: 15px;

  .borderbox13 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 0.6rem 0.7rem;
    background-color: rgba(19, 25, 47, 0.6);
  }
  .borderbox12 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 0.6rem 0.7rem;
    background-color: rgba(19, 25, 47, 0.6);
    // width: inherit;
    // padding: 2% 3%;
  }
}

.iocn_pic{
  width: 100%;
  height: 100%;
  padding: 1% 1%;
}

.bord_wrap {
  width: 100%;
  // height: 7rem;
  height: 79%;
  margin-top: 5px;
  margin-left: 7.5%;
}
.row_wrap{
  display: flex;
  flex-direction: row;
  flex:1;
  padding: 0% 1%;
  width: 60%;
  height: 60%;
}

// .pad{
//   padding-left: 10%;
// }

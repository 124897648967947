$antd-table-head-bg-color: rgba(0, 202, 149, 0.5);
$antd-table-data-bg-color: rgba(0, 202, 149, 0.3);
$antd-collapse-text-color: #cdddf7;

$title-text-color: #bbf5fd;
$home-userinfo-text-color: #b2cfee;

$icons-color: #89e5ff;

$module-text-color: #bcdcff;

$data-text-color: #20dbfd;
$data-text-shadow-color: #00d8ff;

$login-border-color: #2261ae;
@import '../../../assets/styles/common.scss';

.scoll_wrap {
  width: 100%;
  // height: 10rem;
  height: 22%;
  margin-top: 15px;

  .borderbox13 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 0.6rem 0.7rem;
    background-color: rgba(19, 25, 47, 0.6);
  }
}

.bord_wrap {
  width: 100%;
  // height: 7rem;
  height: 79%;
  margin-top: 5px;
}
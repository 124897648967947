$antd-table-head-bg-color: rgba(0, 202, 149, 0.5);
$antd-table-data-bg-color: rgba(0, 202, 149, 0.3);
$antd-collapse-text-color: #cdddf7;

$title-text-color: #bbf5fd;
$home-userinfo-text-color: #b2cfee;

$icons-color: #89e5ff;

$module-text-color: #bcdcff;

$data-text-color: #20dbfd;
$data-text-shadow-color: #00d8ff;

$login-border-color: #2261ae;
.iconfont {
  font-family:"iconfont" !important;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.weather {
  @extend .iconfont;
  color: #FFFFFF;
  font-size: 50px;
  line-height: initial;
}

.module {
  @extend .iconfont;
  color: $icons-color;
  font-size: 50%;
}

.icon_circle {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  align-items: center;
  justify-content: center;
  border: 1px solid $icons-color;
  border-radius: 1rem;
  background-color: rgba(19, 25, 47, 1);
// 图标内大小
  .icont-font {
    @extend .iconfont;
    color: $icons-color;
    font-size: 80%;
  }
}
// 云台控制与导航区图标
.icon_circle2 {
  display: flex;
  width: 1.4rem;
  height: 1.4rem;
  align-items: center;
  justify-content: center;
  border: 2px solid #146292;
  border-radius: 1rem;
  background-color: rgba(19, 25, 47, 1);
  
  .icont-font {
    @extend .iconfont;
    color: white;
    font-size: 120%;
  }
}
.icon_circle2plus {
  display: flex;
  width: 1.4rem;
  height: 1.4rem;
  align-items: center;
  justify-content: center;
  // border: 2px solid #0e0f0f;
  // border-radius: 1rem;
  background-color: rgb(0, 0, 0);
  
  .icont-font {
    @extend .iconfont;
    // color: white;
    // font-size: 120%;
  }
}
// 导航区图标
.icon_circle3 {
  display: flex;
  width: 1.2rem;
  height: 1.2rem;
  align-items: center;
  justify-content: center;
  border: 1px solid $icons-color;
  border-radius: 1rem;
  background-color: rgba(19, 25, 47, 1);
// 图标内大小
  .icont-font {
    @extend .iconfont;
    color: $icons-color;
    font-size: 80%;
  }
}
// 无圆壳图标
.icon_nocircle {
  display: flex;
  width: 1.4rem;
  height: 1.4rem;
  align-items: center;
  justify-content: center;
  // border: 2px solid #146292;
  // border-radius: 1rem;
  background-color: rgba(19, 25, 47, 1);
  
  .icont-font {
    @extend .iconfont;
    color: $icons-color;
    font-size: 150%;
  }
}
.icon-btn {
  @extend .iconfont;
  font-size: 0.8rem;
}
$antd-table-head-bg-color: rgba(0, 202, 149, 0.5);
$antd-table-data-bg-color: rgba(0, 202, 149, 0.3);
$antd-collapse-text-color: #cdddf7;

$title-text-color: #bbf5fd;
$home-userinfo-text-color: #b2cfee;

$icons-color: #89e5ff;

$module-text-color: #bcdcff;

$data-text-color: #20dbfd;
$data-text-shadow-color: #00d8ff;

$login-border-color: #2261ae;
@import '../../../assets/styles/common.scss';

.scoll_wrap {
  width: 100%;
  // height: 10rem;
  height: 100%;
  // margin-top: 5%;

  .borderbox13 {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 0.6rem 0.7rem;
    background-color: rgba(19, 25, 47, 0.6);
  }
}

.bord_wrap {
  margin-left: 6%;
  width: 100%;
  // height: 7rem;
  height: 80%;
  margin-top: 1%;
  margin-left: 14%;
}

.row_wrap{
  display: flex;
  flex-direction: row;
  padding: 0% 0%;
  width: 100%;
  height: 100%;
}

.title_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    // margin-left: -5.5%;
    font-size: 12;
    // font-weight: bold;
    color: $module-text-color;
  }
}

$antd-table-head-bg-color: rgba(0, 202, 149, 0.5);
$antd-table-data-bg-color: rgba(0, 202, 149, 0.3);
$antd-collapse-text-color: #cdddf7;

$title-text-color: #bbf5fd;
$home-userinfo-text-color: #b2cfee;

$icons-color: #89e5ff;

$module-text-color: #bcdcff;

$data-text-color: #20dbfd;
$data-text-shadow-color: #00d8ff;

$login-border-color: #2261ae;
@import '../../../assets/styles/common.scss';

.mon_wrap {
  width: 100%;
  // height: 10.5rem;
  height: 54%;
  padding-top: 0.6rem;
  // .borderbox10 {
    // width: inherit;
    // height: inherit;
    // padding: 0.6rem;
  // }
}
.mon_wrap2 {
  width: 1000000%;
  // height: 10.5rem;
  height: 92%;
  padding-top: 0.6rem;
  // .borderbox10 {
    // width: inherit;
    height: inherit;
    // padding: 0.6rem;
  // }
}

.border_content {
  width: 100%;
  height: 100%;
  padding: 0.6rem;
}

.video_wrap {
  display: flex;
  flex-direction: row;
  // width: 100%;
  // height: inherit;
  // margin-top: 10px;
}

.video2_wrap {
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 108%;
  // margin-top: 10px;
}

.video {
  display: block;
  // border: 1px solid red;
  width: 100%;
  // width: 62%;
  // height: 85%;
}

.flv_wrap {
  position: relative;
  border: 1px solid #b2cfee;
  width: 72%;
  height: 100%;
} 
.buttons_box{
  width: 20;
  height: 8%;
  background-color: rgba(43, 51, 63, 0.7);
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  right: 3%;
  bottom: 1%;
  user-select: none;
  z-index: 10;
} 
.buttons2_box{
  width: 7%;
  height: 8%;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  right:3%;
  // bottom: 1%;
  top:3.5%;
  user-select: none;
  // z-index: 10
}

.jessibuca_btn{
  color: rgb(255, 255, 255);
  margin: 0px 5px;
  padding: 0px 2px;
  cursor: pointer;
  text-align: center;
  font-size: 0.8rem;
}

.buttons_box_right {
  position: absolute;
  right: 0;
}

// .buttons_box{
//   width: 100%;
//   height: 28px;
//   background-color: rgba(43, 51, 63, 0.7);
//   position: absolute;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   left: 0;
//   bottom: 0;
//   user-select: none;
//   z-index: 10;
// }
.monitor_info {
  display: flex;
  width: 26%;
  margin-left: 10px;
  flex-direction: column;
}

.name {
  display: flex;
  width: auto;
  height: fit-content;
  padding: 5px 8px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  background-image: url(../../../assets/images/name_bg.png);
  span {
    color: transparent;
    font-size: 14px;
    font-weight: bold;
    background: linear-gradient(to bottom,#fff,#4db6e5);
    background-clip: text;
  }
}

.content2_wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: inherit;
}
.collapse_wrap {
  display: flex;
  height: auto;
  width: 100%;
  :global {
    .ant-collapse {
      background-color: transparent;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      // height: inherit;
      padding: 3px 8px;
      color: $antd-collapse-text-color;
      font-size: 13px;
    }
    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
.row_wrap{
  display: flex;
  flex-direction: row;
  padding: 3% 0%;
  width: 100%;
  // height: 200%;
}
.row_wrap2{
  display: flex;
  flex-direction: row;
  // padding: 2% 0%;
  width: 100%;
  // height: 200%;
}
.collapse2_wrap {
  flex-direction: column;
  display: flex;
  height: auto;
  width: 70%;
  :global {
    .ant-collapse {
      background-color: transparent;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      // height: inherit;
      padding: 3px 8px;
      color: $antd-collapse-text-color;
      font-size: 13px;
    }
    .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.collapse {
  width: 100%;
  // height: 1.5rem;
  // margin-top: 10px;
  color: $antd-collapse-text-color;
  // border: 1px solid #0E94EA;
  background-color: transparent;
  .panel_control {
    // height: 100%;
    color: $antd-collapse-text-color;
  }
}

.col {
  display: flex;
  justify-content: space-between;
}

.icons {
  display: flex;
  flex-direction: column;
  flex: 1;
  color:$icons-color;
  font-size: small;
  margin-left: 1%;
  align-items:center;
  flex-direction: row;
  background-color: rgba(19, 25, 47, 1);
  &:nth-of-type(1) {
    margin-left: 0;
  }
}

.icon_all {
  display: flex;
  flex-direction: column;
  flex: 1;
  color:white;
  font-size:70%;
  margin-left: 0%;
  align-items:center;
  flex-direction: row;
  background-color: black;
  &:nth-of-type(1) {
    margin-left: 0;
  }
}

.icon_voltage {
  display: flex;
  flex-direction: column;
  flex: 1;
  color:white;
  font-size: 70%;
  margin-left: 7.5%;
  align-items:center;
  flex-direction: row;
  background-color: black;
  &:nth-of-type(1) {
    margin-left: 0;
  }
}

.icon_colorblue{
  color:$icons-color ;
}
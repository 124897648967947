$antd-table-head-bg-color: rgba(0, 202, 149, 0.5);
$antd-table-data-bg-color: rgba(0, 202, 149, 0.3);
$antd-collapse-text-color: #cdddf7;

$title-text-color: #bbf5fd;
$home-userinfo-text-color: #b2cfee;

$icons-color: #89e5ff;

$module-text-color: #bcdcff;

$data-text-color: #20dbfd;
$data-text-shadow-color: #00d8ff;

$login-border-color: #2261ae;
.map_wrap {
  width: 100%;
  // height: 22rem;
  height: 42%;
}

.listitem {
  position: relative;
  display: block;
  font-size: 100%-130%;
  left: 50px;
  // padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.row_wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  // width: 100%;
  // height: inherit;
  // margin-top: 10px;
}

.icons {
  display: flex;
  flex-direction: column;
  flex: 1;
  // color:$icons-color;
  color: whitesmoke;
  font-size:medium;
  margin-left: 25px;
  align-items:center;
  flex-direction: row;
  background-color: black;
  &:nth-of-type(1) {
    margin-left: 0;
  }
}
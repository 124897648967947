$antd-table-head-bg-color: rgba(0, 202, 149, 0.5);
$antd-table-data-bg-color: rgba(0, 202, 149, 0.3);
$antd-collapse-text-color: #cdddf7;

$title-text-color: #bbf5fd;
$home-userinfo-text-color: #b2cfee;

$icons-color: #89e5ff;

$module-text-color: #bcdcff;

$data-text-color: #20dbfd;
$data-text-shadow-color: #00d8ff;

$login-border-color: #2261ae;
.soil_chart {
  width: 80%;
  height: 50%
  // margin-top: 0.5rem;
}

.air_piechart {
  display: flex;
  flex-direction: row;
  width: 100%;
  // height: 9rem;
  height: 100%;
  margin-top: 1%;
}
$antd-table-head-bg-color: rgba(0, 202, 149, 0.5);
$antd-table-data-bg-color: rgba(0, 202, 149, 0.3);
$antd-collapse-text-color: #cdddf7;

$title-text-color: #bbf5fd;
$home-userinfo-text-color: #b2cfee;

$icons-color: #89e5ff;

$module-text-color: #bcdcff;

$data-text-color: #20dbfd;
$data-text-shadow-color: #00d8ff;

$login-border-color: #2261ae;
.content_wrap {
  display: flex;
  width: 100%;
  height: 96%;
  flex-wrap: nowrap;
  .left_content {
    width: 30%;
    // height: auto;
    height: 100%;
    padding: 0 0.6rem 0.6rem 0.6rem;
  }
  .center_content {
    position: relative;
    width: 40%;
    // height: auto;
    height: 100%;
    padding: 0 0.6rem 0.6rem 0.6rem;
  }
  .right_content {
    width: 30%;
    // height: auto;
    height: 100%;
    padding: 0 0.6rem 0.6rem 0.6rem;
  }
  // .center-page {
  //   flex: 1;
  // }
}
.row_wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  // width: 100%;
  // height: inherit;
  // margin-top: 10px;
}
.icons {
  display: flex;
  flex-direction: column;
  flex: 1;
  // color:$icons-color;
  color: whitesmoke;
  font-size:medium;
  margin-left: 25px;
  align-items:center;
  font-size: 50%;
  flex-direction: row;
  background-color: black;
  &:nth-of-type(1) {
    margin-left: 0;
  }
}

.iconsuse {
  display: flex;
  flex-direction: column;
  flex: 1;
  // color:$icons-color;
  color: $icons-color;
  font-size:medium;
  margin-left: 25px;
  align-items:center;
  font-size: 50%;
  flex-direction: row;
  background-color: black;
  &:nth-of-type(1) {
    margin-left: 0;
  }
}
.mon_wrap {
  width: 100%;
  // height: 10.5rem;
  height: 42%;
  padding-top: 0.6rem;
  // .borderbox10 {
    // width: inherit;
    // height: inherit;
    // padding: 0.6rem;
  // }
}
$antd-table-head-bg-color: rgba(0, 202, 149, 0.5);
$antd-table-data-bg-color: rgba(0, 202, 149, 0.3);
$antd-collapse-text-color: #cdddf7;

$title-text-color: #bbf5fd;
$home-userinfo-text-color: #b2cfee;

$icons-color: #89e5ff;

$module-text-color: #bcdcff;

$data-text-color: #20dbfd;
$data-text-shadow-color: #00d8ff;

$login-border-color: #2261ae;
@import '../../../assets/styles/common.scss';

.mos_wrap {
  width: 100%;
  // height: 6rem;
  height: 16.3%;
  margin-top: 2%;
  .borderbox7 {
    // width: inherit;
    // height: inherit;
    padding: 0.2rem 0.6rem;
  }
  .tabel_wrap {
    // size: 10px;
    margin-top: 1%;
    @extend .antd_tabel;
  }
}
$antd-table-head-bg-color: rgba(0, 202, 149, 0.5);
$antd-table-data-bg-color: rgba(0, 202, 149, 0.3);
$antd-collapse-text-color: #cdddf7;

$title-text-color: #bbf5fd;
$home-userinfo-text-color: #b2cfee;

$icons-color: #89e5ff;

$module-text-color: #bcdcff;

$data-text-color: #20dbfd;
$data-text-shadow-color: #00d8ff;

$login-border-color: #2261ae;
@import '../../../assets/styles/common.scss';

.weather_wrap {
  width: 100%;
  // height: 30rem;
  height:100%;

  .borderbox12 {
    // width: inherit;
    padding: 2% 3%;
  }
}

.top {
  display: flex;
  flex-direction: column;

  .factor {
    display: flex;
    margin-top: 0%;
    align-items: center;
    justify-content: space-around;
    color: #FFFFFF;

    &:nth-of-type(1) {
      margin-top: 0;
    }

    .temperature {
      // @extend .data_text;
      // margin: auto 0 auto 10px;
      color: #FFFFFF;
      font-size: 90%;
    }

    .icons {
      display: flex;
      flex: 1;
      margin-left: 2%;
      align-items: center;
      flex-direction: row;

      &:nth-of-type(1) {
        margin-left: 0;
      }
    }
  }
}

.weather_box {
  display: flex;
  align-items:center;
  width: 30%;
}

.data {
  display: flex;
  margin-left: 2%;
  flex-direction: column;

  .number {
    @extend .data_text;
    font-size: 50%;
  }
  .label {
    font-size: 50%;
  }
}


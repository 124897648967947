$antd-table-head-bg-color: rgba(0, 202, 149, 0.5);
$antd-table-data-bg-color: rgba(0, 202, 149, 0.3);
$antd-collapse-text-color: #cdddf7;

$title-text-color: #bbf5fd;
$home-userinfo-text-color: #b2cfee;

$icons-color: #89e5ff;

$module-text-color: #bcdcff;

$data-text-color: #20dbfd;
$data-text-shadow-color: #00d8ff;

$login-border-color: #2261ae;
// @import '../../../assets/styles/weather.scss';

.wrap {
  display: flex;
  width: 100%;
  height: 4%;
  justify-content: center;
  .decoration10 {
    position: relative;
    // width: 33.3%;
    width: 28.5%;
    // height: 0.18rem;
    height: 10%
  }
  .decoration10_reverse {
    @extend .decoration10;
    transform: rotateY(180deg);
  }
}

.title_wrap {
  display: flex;
  width: 43%;
  height: 100%;
  justify-content: center;
  .decoration8 {
    // width: 7.5rem;
    width: 23%;
    // height: 1.8rem;
    height: 100%;
  }
  .title {
    position: relative;
    // width: 18rem;
    width: 55%;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    .text {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 100%;
      margin-bottom: 0;
      font-size: 150%;
      color: $title-text-color;
      transform: translate(-50%);
    }
  
    .decoration6 {
      // width: 14rem;
      width: 78%;
      height: 18%;
      // height: 0.3rem;
    }
  
    .bottom {
      position: absolute;
      // bottom: -1rem;
      bottom: -59%;
      left: 50%;
      transform: translate(-50%);
    }
  }
}

.weather_wrap {
  position: absolute;
  display: flex;
  left: 1rem;
  top: 0.5rem;
  text-align: right;
  color: #fff;
}

.temperature {
  margin: auto 0 auto 10px;
  color: #FFFFFF;
  font-size: 35px;
}

.menu_wrap {
  position: absolute;
  left: 1rem;
  top: 1rem;
  width: 4rem;
  height: 1.5rem;
  .borderbox12 {
    width: inherit;
    height: inherit;
    padding: 0.1rem;
  }
  span {
    display: inline-flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: #f1f1f1;
  }
}

.userinfo {
  position: absolute;
  display: flex;
  // top: 1rem;
  // right: 1rem;
  top: 2.5%;
  right: 2.5%;
  width: auto;
  // height: 1rem;
  height: 2.5%;
  flex-direction: row;
  color: $home-userinfo-text-color;
  font-size: 14px;
  p {
    margin-left: 5px;
    cursor: pointer;
  }
}
// 验证码
.block{
  width: 100px;
  height: 40px;
  border: 1px solid grey;
  float: left;
  // background-image: url(img/1.png);
  background-size: 100%;
  overflow: hidden;
}

.look{
  float: left;
  color: black;
  font-size: 12px;
  position: relative;
  cursor: pointer;
  top: 28px;
  left: 5px;    
}

.count{
  float: left
}

.num{
  display: inline-block;
  line-height: 40px;
  width: 16.5px;
  text-align: center;
}

.userinfo2 {
  position: absolute;
  display: flex;
  // top: 1rem;
  // right: 1rem;
  top: 2.5%;
  right: 19.5%;
  width: auto;
  // height: 1rem;
  height: 2.5%;
  flex-direction: row;
  color: $home-userinfo-text-color;
  font-size: 14px;
  p {
    margin-left: 5px;
    cursor: pointer;
  }
}

.userinfo3 {
  position: absolute;
  display: flex;
  // top: 1rem;
  // right: 1rem;
  top: 2.5%;
  right: 10.5%;
  width: auto;
  // height: 1rem;
  height: 2.5%;
  flex-direction: row;
  color: $home-userinfo-text-color;
  font-size: 14px;
  p {
    margin-left: 5px;
    cursor: pointer;
  }
}
.data_text {
  color: $data-text-color;
  font-weight: bold;
  text-shadow: 0 0 25px $data-text-shadow-color;
}

.title_wrap {
  display: flex;
  flex-direction: row;
  align-items: center;

  .title {
    margin-left: 6px;
    font-size: 12px;
    font-weight: bold;
    color: $module-text-color;
  }
}

.antd_tabel {
  :global {
    .ant-table {
      background-color: $antd-table-data-bg-color;
    }
    .ant-table-thead > tr > th {
      color: white;
      font-size: 14px;
      background-color: $antd-table-head-bg-color;
    }
    .ant-table-tbody > tr > td ,.ant-table-thead > tr > th{
      border: none;
      font-size: 60%;
      text-align: center !important;
      // text-align: center !important;
    }
    .ant-table-tbody {
      color: $data-text-color;
      text-shadow: 0 0 25px $data-text-shadow-color;
    }
    // 选中行样式
    .ant-table-tbody {
      > tr:hover:not(.ant-table-expanded-row) > td,.ant-table-row-hover,.ant-table-row-hover>td{
        background-color: initial !important;
      }
    }
  }
}
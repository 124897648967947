$antd-table-head-bg-color: rgba(0, 202, 149, 0.5);
$antd-table-data-bg-color: rgba(0, 202, 149, 0.3);
$antd-collapse-text-color: #cdddf7;

$title-text-color: #bbf5fd;
$home-userinfo-text-color: #b2cfee;

$icons-color: #89e5ff;

$module-text-color: #bcdcff;

$data-text-color: #20dbfd;
$data-text-shadow-color: #00d8ff;

$login-border-color: #2261ae;
@import '../../../assets/styles/common.scss';

.soil_wrap {
  width: 100%;
  // height: 11rem;
  // margin-top: 5px;
  height: 34%;
  margin-top: 1%;
  .borderbox13 {
    // width: inherit;
    // height: inherit;
    padding: 0.6rem 0.7rem 0 0.7rem;
  }
}

.charts {
  width: 100%;
  height: 100%;
  // margin-top: 10px;
}

.factor {
  display: flex;
  // margin: 10px 0;
  align-items: center;
  justify-content: space-around;
  color: #FFFFFF;

  .temperature {
    // @extend .data_text;
    margin: auto 0 auto 10px;
    color: #FFFFFF;
    font-size: 35px;
  }

  .icons {
    display: flex;
    flex: 1;
    margin-left: 10%;
    align-items: center;
    flex-direction: row;

    &:nth-of-type(1) {
      margin-left: 0;
    }
  }
}

.data {
  display: flex;
  margin-left: 1%;
  flex-direction: column;

  .number {
    @extend .data_text;
    font-size: 50%;
    display: flex;
    align-items: center;
    i {
      display: inline-block;
      margin-left: 10%;
      color: #FFFFFF;
      font-size: 50%;
      font-weight: normal;
      font-style: normal;
    }
  }
  .label {
    font-size: 50%;
  }
}